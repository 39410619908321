import { graphql, HeadFC } from 'gatsby';
import React from 'react';
import ServiceContentSection from '../components/Services/ServiceContentSection';
import ServiceTitle from '../components/Titles/ServiceTitle';
import * as styles from './apropos.module.scss';

const AboutPage = ({ data }: any) => {
  const { about, general } = data;

  return (
    <main>
      <ServiceTitle text={about.title} />

      {
        about.sections.map((section: any, index: number) => (
          <ServiceContentSection
            key={section._key}
            index={index + 1}
            title={section.title}
            content={section.content}
            image={section.image.asset} />
        ))
      }

      <div className={styles.mapContainer}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2664.71325136027!2d-0.7763365362457274!3d48.096475208081166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480902159755cfd1%3A0x63121125b15716cd!2sMenuiserie%20Bordeau!5e0!3m2!1sen!2sfr!4v1667851858110!5m2!1sen!2sfr" height="450" style={{ border: 0, width: '100vw' }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </main>
  );
};

export const Head: HeadFC = ({ data }: any) => {
  const { about, general } = data;

  return (
    <>
      <title>{about.title} | {general.title}</title>
      <meta name="description" content={general.description?.slice(0, 150)} />
    </>
  );
};

export const query = graphql`
  {
    general: sanityGeneral {
      address {
        street
        city
        zip
        country
      }
    }
        
    about: sanityAbout {
      title
      sections {
        _key
        _type
        title
        content
        image {
          asset {
            url
            gatsbyImageData(
              width: 200
              formats: [AUTO, WEBP, AVIF]
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }

    general: sanityGeneral {
      title
      description
    }
  }
`;

export default AboutPage;